import style from "./index.module.css";

const Splash = props => {
  return (
    <main className={style.main__container}>
      <p>ANGEL</p>
    </main>
  );
};

export default Splash;